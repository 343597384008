import MainImg from "../images/chooseUs/mainp.png";
import Box1 from "../images/chooseUs/camp1.jpg";
import Box2 from "../images/chooseUs/camp2.jpg";
import Box3 from "../images/chooseUs/camp3.jpg";

function ChooseCamp() {
  return (
    <>
      <section className="choose-section" >
        <div className="container" >
          <div className="choose-container">
            <img
              className="choose-container__img" style={{marginTop:'-100px'}}
              src={MainImg}
              alt="car_img"
            />
            <div className="text-container">
              <div className="text-container__left">
              <h4>We are the best choice</h4>
                <h2>Loaded options in your friendly budget</h2>


                <p>
                Experience the ultimate adventure with our camper rentals in Windhoek, Namibia. Discover fantastic deals on a range of dependable campers, perfectly suited to your wanderlust. Our dedication to affordability ensures you can enjoy premium services and products while staying within your budget. Hit the road and uncover the breathtaking beauty of Windhoek, Namibia at your own pace with our tailor-made camper rental options. Don't let this opportunity slip away to save big on your next adventure. We're your go-to destination for stress-free and wallet-friendly camper rentals in Windhoek, Namibia. Secure your camper today and make the most out of your journey.
                </p>
               
              </div>
              <div className="text-container__right">
                <div className="text-container__right__box">
                  <img src={Box1} alt="car-img" style={{borderRadius:'35px'}} />
                  <div className="text-container__right__box__text">
                    <h4>Everything Available</h4>
                    <p>
                    Hire of Safari, Camping and 4×4 equipment.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box2} alt="coin-img" style={{borderRadius:'35px'}} />
                  <div className="text-container__right__box__text">
                    <h4>As Per Your Choice</h4>
                    <p>
                    Self Drive and Guided Wildlife and Adventure Safaris.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box3} alt="coin-img"style={{borderRadius:'35px'}} />
                  <div className="text-container__right__box__text">
                    <h4>Road Side Assistance</h4>
                    <p>
                    24 hour Roadside Assistance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChooseCamp;
