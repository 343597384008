import { useEffect, useState } from "react";
import { db } from "./firebase.js";
import {
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  collection,
  addDoc,
} from "firebase/firestore";
import PhoneInput from "react-phone-number-input";
import emailjs from "@emailjs/browser";
import "./numberinput.css"; // Import your custom styles

function BookCarHome({ carName }) {
  const [carTypes, setCarTypes] = useState([]);
  const [selectedCar, setSelectedCar] = useState(carName);
  const [carImgUrl, setCarImgUrl] = useState("");
  const [carData, setCarData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        setCarData(data); // Update this line
        setCarTypes(data.map((car) => car.name));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedCar]);


  useEffect(() => {
    setCarImgUrl('')
  }, [carName]);

  useEffect(() => {
    
    console.log(carName)
  setSelectedCar(carName)
  setCarData('')
      
      const handleSelectChange = async () => {
        // Find the selected car in the data and set its image URL
        const selectedCarData = carData.find((car) => car.name === carName);
        if (selectedCarData) {
          setCarImgUrl(selectedCarData.img);
        } else {
          // If the data is not in the state, fetch it from Firestore
          try {
            const querySnapshot = await getDocs(collection(db, "carsdata"));
            const data = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            setCarData(data);
            const newSelectedCarData = data.find(
              (car) => car.name === carName
            );
            if (newSelectedCarData) {
              setCarImgUrl(newSelectedCarData.img);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      };
  handleSelectChange()
    
  console.log('Car Changed')
  
}, [carName]);




  const handleSelectChange = async (event) => {

   setSelectedCar(carName);

    const selectedCarName = event.target.value;
    setSelectedCar(selectedCarName);

    // Find the selected car in the data and set its image URL
    const selectedCarData = carData.find((car) => car.name === selectedCarName);
    if (selectedCarData) {
      setCarImgUrl(selectedCarData.img);
    } else {
      // If the data is not in the state, fetch it from Firestore
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCarData(data);
        const newSelectedCarData = data.find(
          (car) => car.name === selectedCarName
        );
        if (newSelectedCarData) {
          setCarImgUrl(newSelectedCarData.img);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  //////////////

  const [modal, setModal] = useState(false); //  class - active-modal
  // booking car
  const [carType, setCarType] = useState("");
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [pickTime, setPickTime] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [carImg, setCarImg] = useState("");
  // modal infos
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipCode] = useState("");

  const [pickingTime, setPickingTime] = useState("");
  const [droppingTime, setDroppingTime] = useState("");

  // taking value of modal inputs
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleAge = (e) => {
    setAge(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

   const handleAddress = (e) => {
    setAddress(e.target.value);
    };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleZip = (e) => {
    setZipCode(e.target.value);
  };

  // open modal when all inputs are fulfilled
  const openModal = (e) => {
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");

    if (
      !pickUp ||
      !dropOff ||
      !pickTime ||
      !dropTime ||
      !droppingTime ||
      !selectedCar ||
      !pickingTime
    ) {
      errorMsg.style.display = "flex";
      window.alert("Error: Please Fill All Fields! ");
    } else {
      setModal(!modal);
      const modalDiv = document.querySelector(".booking-modal");
      modalDiv.scroll(0, 0);
      errorMsg.style.display = "none";
    }
  };

  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  // confirm modal booking

  const handleTimeChange = (e) => {
    setPickingTime(e.target.value);
  };

  const handleTimeChange2 = (e) => {
    setDroppingTime(e.target.value);
  };

  const convertTo12HourFormat = (timeString) => {
    const [hour, minute] = timeString.split(":");
    const parsedHour = parseInt(hour, 10);
    const ampm = parsedHour >= 12 ? "PM" : "AM";
    const formattedHour = parsedHour % 12 === 0 ? 12 : parsedHour % 12;
    return `${formattedHour}:${minute} ${ampm}`;
  };


  const formattedTimes = convertTo12HourFormat(pickingTime);
  const formattedTimes1 = convertTo12HourFormat(droppingTime);


  const sendEmail = () => {

    if (
      !pickUp ||
      !dropOff ||
      !pickTime ||
      !dropTime ||
      !droppingTime ||
      !selectedCar ||
      !email ||
      !name ||
      !city ||
      !zipcode ||
      !age ||
      !phone ||
      !pickingTime 
      
    ) {
      
      window.alert("Error: Please Fill All Fields! ");
    } else {


    const formattedTime = convertTo12HourFormat(pickingTime);
    const formattedTime1 = convertTo12HourFormat(droppingTime);

    const templateParams = {
      // Add your template parameters here, for example:
     // to_name: 'Recipient Name', // Customize as per your template requirements
      //from_name: 'Your Name',    // Customize as per your template requirements
     // message: 'This is a static test message', // Customize as per your template requirements
      email: email,
      car: selectedCar,
      pickup: pickUp,
      dropoff: dropOff,
      pdate: pickTime,
      ddate: dropTime,
      ptime: formattedTime,
      dtime: formattedTime1,
      pax: '..',
      name: name,
      age: age,
      address: zipcode,
      country: city,
     phone: phone,


    };
  
    emailjs
      .send('service_euihuio', 'template_4gh9wy8', templateParams, 'Rb8ybcgbS_-jhGL6J')
      .then(
        (response) => {
          console.log('Message sent successfully:', response.status, response.text);
        },
        (error) => {
          console.error('Failed to send message:', error.text);
        }
      );

      window.alert("We have recieved your quote request. You will get a response on your email soon.");
    }
  };



  const postBooking = async () => {
    try {
      // Condition to validate Booking Form Fields

      if (!selectedCar || !pickUp || !dropOff || !pickTime || !dropTime || !pickingTime || !droppingTime || !email)
        window.alert("Error: Fill All Fields!");
      else {
        const formattedTime = convertTo12HourFormat(pickingTime);
        const formattedTime1 = convertTo12HourFormat(droppingTime);
        // const pickupFieldValue = `${formattedTime} ${pickTime}`;

        await db.collection("bookingsdata").add({
          carname: selectedCar,
          pickup: pickUp,
          dropoff: dropOff,
          picktime: pickTime,
          droptime: dropTime,
          pickingtime: formattedTime,
          droppingtime: formattedTime1,
          email: email,
          type: 'Car',
          mailed:'Pending',
         
        });

        // Log a message to the console if the document was added successfully
        console.log("Booking Details Posted!");

        // Show an alert to the user
        window.alert("We have recieved your quote request. You will get a response on your email soon.");

        // Close the modal
        setModal(false);

        // Display the "booking done" message
        const doneMsg = document.querySelector(".booking-done");
        doneMsg.style.display = "flex";

        // Clear the form fields
        setPickUp("");
        setDropOff("");
        setPickTime("");
        setDropTime("");
        setCarType("");
        setName("");
        setLastName("");
        setPhone("");
        setAge("");
        setEmail("");
        // setAddress("");
        setCity("");
        setPickingTime("");
        setDroppingTime("");
        setZipCode("");
      }
    } catch (error) {
      // Log any errors to the console
      console.error(error);
    }
  };

  const confirmBooking = (e) => {
    e.preventDefault();
   // postBooking();
   sendEmail();
  };

  // taking value of booking inputs
  const handleCar = (e) => {
    const selectedCarName = e.target.value;
    setCarType(selectedCarName);

    // Find the selected car in the data and set its image URL
    const selectedCarData = carData.find((car) => car.name === selectedCarName);
    if (selectedCarData) {
      setCarImg(selectedCarData.img);
    }
  };

  const handlePick = (e) => {
    setPickUp(e.target.value);
  };

  const handleDrop = (e) => {
    setDropOff(e.target.value);
  };

  const handlePickTime = (e) => {
    setPickTime(e.target.value);
  };

  const handleDropTime = (e) => {
    setDropTime(e.target.value);
  };

 
  // hide message
  const hideMessage = () => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  };

  return (
    <>
      <section id="booking-section" className="book-section" style={{marginTop:'-100px'}} >
        {/* overlay */}
        <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div>

        <div className="plan-container__title" style={{ paddingTop: "30px" }}>
          <h2>Car Booking Quote  </h2>
        </div>

        <div className="container">
          <div className="book-content">
            <div
              className="book-content__box"
              style={{
                backgroundColor: '#9f5e37',
                backgroundImage: 'linear-gradient(225deg, #9f5e37 0%, #d8bca8 100%)'
                

              }}
            >
              <h2>Get A Quote</h2>
              <p className="error-message">
                All fields required! <i className="fa-solid fa-xmark"></i>
              </p>
              <p className="booking-done">
               We have recieved your quote request. You will recieve an email with details soon.
               {" "}
                <i onClick={hideMessage} className="fa-solid fa-xmark"></i>
              </p>
              <form className="box-form">
                <div className="box-form__car-type">
                  <label>
                    <i className="fa-solid fa-car"></i> &nbsp; Select Your Car
                    Type <b>*</b>
                  </label>

                  <select value={selectedCar} onChange={handleSelectChange}  style={{color:'#444443'}} >
                    <option>Select your car </option>
                    {carTypes.map((carType, index) => (
                      <option key={index} value={carType}>
                        {carType}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="box-form__car-type">
                  <label>
                    <i className="fa-solid fa-location-dot"></i> Pick-up{" "}
                    <b>*</b>
                  </label>
                  <select value={pickUp} onChange={handlePick} style={{color:'#444443'}}  >
                    <option>Select pick up location</option>
                    <option>Our Office</option>
                    <option> Hosea Kutako Airport Windhoek </option>
                    <option>Eros Airport Windhoek </option>
                    <option>Walvisbay Airport </option>
                    <option>Ondangwa Airport  </option>
                    <option>Katim Mulilo Airport </option>
                    <option>Rundu Airport  </option>
                    <option>Katima Mulilo Airport</option>
                  </select>
                </div>
                <div className="box-form__car-type">
                  <label>
                    <i className="fa-solid fa-location-dot"></i> Drop-off{" "}
                    <b>*</b>
                  </label>

                
                  <select value={dropOff} onChange={handleDrop} style={{color:'#444443'}}  >
                    <option>Select drop off location</option>
                    <option>Our Office</option>
                    <option> Hosea Kutako Airport Windhoek </option>
                    <option>Eros Airport Windhoek </option>
                    <option>Walvisbay Airport </option>
                    <option>Ondangwa Airport  </option>
                    <option>Katim Mulilo Airport </option>
                    <option>Rundu Airport  </option>
                    <option>Katima Mulilo Airport</option>
                  </select>
                </div>



                <div className="box-form__car-time">
                  <label htmlFor="picktime">
                    <i className="fa-regular fa-calendar-days "></i>
                    Pick-up Date <b>*</b>
                  </label>
                  <input
                    id="picktime"
                    value={pickTime}
                    onChange={handlePickTime}
                    type="date"
                  ></input>

                  <label htmlFor="droptime" style={{ marginTop: "13px" }}>
                    Pick-up Time <b>*</b>
                  </label>
                  <input
                    id="droptime"
                    value={pickingTime}
                    onChange={handleTimeChange}
                    type="time"
                    className="input-time"
                    style={{ width: "150px" }}
                  />
                </div>

                <div className="box-form__car-time">
                  <label htmlFor="droptime">
                    <i className="fa-regular fa-calendar-days "></i>
                    Drop-of Date <b>*</b>
                  </label>
                  <input
                    id="droptime"
                    value={dropTime}
                    onChange={handleDropTime}
                    type="date"
                  ></input>

                  <label htmlFor="droptime" style={{ marginTop: "13px" }}>
                    Drop-off Time <b>*</b>
                  </label>
                  <input
                    id="droptime"
                    value={droppingTime}
                    onChange={handleTimeChange2}
                    type="time"
                    className="input-time"
                    style={{ width: "150px" }}
                  />
                </div>

              </form>

              <button
                onClick={openModal}
                type="submit"
                style={{
                  alignSelf: "center",
                  width: "260px",
                  marginTop: "14px",
                  backgroundColor: '#8B4513',
                  //backgroundImage: 'linear-gradient(275deg, #00690a 0%, #3a9e0e 79%)',
                  //transition: "box-shadow 0.3s ease-in-out",
                  
                  borderRadius: "33px",
                }}
              >
Proceed              </button>


            </div>
          </div>
        </div>
      </section>

      {/* modal ------------------------------------ */}
      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        {/* title */}
        <div
          className="booking-modal__title"
          style={{
            backgroundColor: "#5078f2",
            backgroundImage: "linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)",
          }}
        >
          <h2>Complete Reservation</h2>
          <i onClick={openModal} className="fa-solid fa-xmark"></i>
        </div>
        {/* message */}
        <div className="booking-modal__message">
          <h4 style={{ color: "blue" }}>
            <i className="fa-solid fa-circle-info"></i> Upon completing this
            reservation enquiry:
          </h4>
          <p>
            Our staff will contact you at the earliest with confirmation
            details.
          </p>
        </div>
        {/* car info */}
        <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5 style={{ color: "blue" }}>Location & Date</h5>
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Pick-Up Date & Time</h6>
                  <p>
                    {pickTime} / {formattedTimes}
                  </p>
                </div>
              </span>
            </div>
            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Drop-Off Date & Time</h6>
                  <p>
                    {dropTime} / {formattedTimes1}
                  </p>
                </div>
              </span>
            </div>
            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Pick-Up Location</h6>
                  <p>{pickUp}</p>
                </div>
              </span>
            </div>
            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Drop-Off Location</h6>
                  <p>{dropOff}</p>
                </div>
              </span>
            </div>
          </div>
          <div className="booking-modal__car-info__model">
            <h5 style={{ color: "blue" }}>
              <span>Car -</span> {selectedCar}
            </h5>
            {carImgUrl && <img src={carImgUrl} alt="car_img" />}
          </div>
        </div>
        {/* personal info */}
        <div className="booking-modal__person-info" style={{}}>
          <h4 style={{ color: "blue" }}>Personal Information</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  Full Name <b>*</b>
                </label>
                <input
                  value={name}
                  onChange={handleName}
                  type="text"
                  placeholder="Enter full name"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

{/* 
              <span>
                <label>
                  Last Name <b>*</b>
                </label>
                <input
                  value={lastName}
                  onChange={handleLastName}
                  type="text"
                  placeholder="Enter your last name"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
*/}
              <span>
                <div className="phoneInputContainer">
                  <label className="label">
                    Phone Number <b>*</b>
                  </label>
                  <div className="phoneInputWrapper">
                    <PhoneInput
                      international
                      defaultCountry="US"
                      value={phone}
                      onChange={setPhone}
                      placeholder="Enter your phone number"
                      className="phoneInput"
                    />
                  </div>
                  <p className="error-modal">This field is required.</p>
                </div>
              </span>

              <span>
                <label>
                  Age <b>*</b>
                </label>
                <input
                  value={age}
                  onChange={handleAge}
                  type="number"
                  placeholder="Enter age"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>
            <div className="info-form__1col">
              <span>
                <label>
                  Email <b>*</b>
                </label>
                <input
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Enter your email address"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              {/*
              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                  value={address}
                  onChange={handleAddress}
                  type="text"
                  placeholder="Enter your street address"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
              */}
            </div>
            <div className="info-form__2col">
              <span>
                <label>
                  Country <b>*</b>
                </label>
                <input
                  value={city}
                  onChange={handleCity}
                  type="text"
                  placeholder="Enter your country"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>
              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                  value={zipcode}
                  onChange={handleZip}
                  type="text"
                  placeholder="Enter your address"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>
            </div>
            <div className="reserve-button">
              <button
                onClick={confirmBooking}
                style={{
                  backgroundColor: "brown",
                  backgroundImage:
                    "linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)",
                }}
              >
                Send Quote Request
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BookCarHome;
