import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import Testimonials from "../components/Testimonials";
import { Helmet } from "react-helmet";

function TestimonialsPage() {


  return (
    <>

        <Helmet>
        <title>
        Testimonials - CE Car Rental
        </title>

        <meta
          name="description"
          content="Discover the best car rental deals with CE Car Rental. Providing top-quality car renting services in Windhoek, Namibia. Your ultimate car renting experience awaits with CE Car Rental providing car rental services to Hosea Kutako Airport Windhoek Eros Airport Windhoek,  Walvisbay Airport,  Ondangwa Airport, Katim Mulilo Airport, Rundu Airport,  Katima Mulilo Airport"
        />
        {/* Add more meta tags as needed */}
      </Helmet>


      <section className="testimonial-page">
        <HeroPages name="Testimonials" />
        <Testimonials />
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3 style={{color:'#7CFC00'}}>+264 81 319 9459</h3> 
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default TestimonialsPage;
