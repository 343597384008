import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import AboutMain from "../images/about/about-main.jpg";
import Box1 from "../images/about/icon1.png";
import Box2 from "../images/about/icon2.png";
import Box3 from "../images/about/icon3.png";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>About Us - CE Car Rental</title>

        <meta
          name="description"
          content="Learn about CE Car Rental, your go-to destination for affordable and diverse vehicle rental options in Windhoek, Namibia. Discover reliable transportation solutions with a fleet of well-maintained vehicles, competitive pricing, and a dedication to customer satisfaction. Explore the convenience of reliable transportation at your fingertips with CE Car Rental in the beautiful Windhoek, Namibia."
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <section className="about-page">
        <HeroPages name="About" />
        <div className="container">
          <div className="about-main">
            <img
              className="about-main__img"
              src={'https://images.pexels.com/photos/7144174/pexels-photo-7144174.jpeg?auto=compress&cs=tinysrgb&w=600'}
              alt="car-rental dealership"
            />
            <div className="about-main__text">
              <h3>About Company</h3>
              <h2>You start the engine and your adventure begins</h2>
              <p>
              Discover the ease of exploring beautiful landscapes with CE Car Rental. Located in Windhoek, Namibia, we offer a wide range of reliable vehicles to suit your needs. Whether you're touring scenic landscapes or navigating city streets, CE Car Rental ensures a hassle-free experience with well-maintained vehicles, competitive pricing, and a dedication to customer satisfaction. Experience the convenience of reliable transportation with CE Car Rental in Windhoek, Namibia.

              </p>
              <div className="about-main__text__icons">
                <div className="about-main__text__icons__box">
                  <img src={Box1} alt="car-icon" />
                  <span>
                    <h4>20+</h4>
                    <p>Cars </p>
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  <img src={Box2} alt="car-icon" />
                  <span>
                    <h4>1</h4>
                    <p>
                      <a
                       // href="https://maps.app.goo.gl/WfAKd6jjS21ASWgw9"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Rental Outlet
                      </a>
                    </p>
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  <img src={Box3} alt="car-icon" className="last-fk" />
                  <span>
                    <h4>1</h4>
                    <p>Repair Shop</p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/*   <PlanTrip /> */}
         
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>Book a car by getting in touch with us</h2>
            <span>
              <i className="fa-solid fa-phone"></i>
              <h3 style={{color:'#7CFC00'}}>☎+264 81 319 9459</h3> 
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
