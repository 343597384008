import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import { Link } from "react-router-dom";
import BookCar from "../components/BookCar";
import BookCamper from "../components/BookCamper";

import { Helmet } from "react-helmet";

function Confirm()
{

  const styles = {
    btn: {
      backgroundColor: '#9f5e37',
      backgroundImage: 'linear-gradient(105deg, #9f5e37 0%, #3d281a 100%)', 
      transition: 'box-shadow 0.3s ease-in-out',
      fontSize: '18px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px 20px',
      margin: '5px', // add margin for spacing between buttons
      borderColor:'brown',
      borderRadius: '8px',
    },
  };

  const bookBtn = () => {
    document.querySelector('#carz').scrollIntoView({ behavior: 'smooth' });
  };

  const bookBtn2 = () => {
    document.querySelector('#camperz').scrollIntoView({ behavior: 'smooth' });
  };



  return (
    <>
      <Helmet>
        <title>Confirm Booking - CE Car Rental</title>

        <meta
          name="description"
          content="Learn about CE Car Rental, your go-to destination for affordable and diverse vehicle rental options in Windhoek, Namibia. Discover reliable transportation solutions with a fleet of well-maintained vehicles, competitive pricing, and a dedication to customer satisfaction. Explore the convenience of reliable transportation at your fingertips with CE Car Rental in the beautiful Windhoek, Namibia."
        />
        {/* Add more meta tags as needed */}
      </Helmet>


      <section className="about-page">
      <HeroPages name="Confirm Booking" />
        
     <div style={{height:'300px'}}>

     <div className="container">
          
          <div className="hero-content__text__btns" style={{alignItems:'center' ,justifyContent:'center'}}>
         
         
            <Link onClick={bookBtn} className="hero-content__text__btns__book-ride" to="/confirm" style={styles.btn}>
              Book Car
            </Link>


            <Link onClick={bookBtn2} className="hero-content__text__btns__book-ride" to="/confirm" style={styles.btn}>
              Book Camper
            </Link>
                  
                  
        </div>


      </div>

     </div>

        <BookCar/>
        <BookCamper/>
      </section>

      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>Book a car by getting in touch with us</h2>
            <span>
              <i className="fa-solid fa-phone"></i>
              <h3 style={{color:'#7CFC00'}}>☎+264 81 319 9459</h3> 
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Confirm;
