import { useState } from "react";

function Faq() {
  const [activeQ, setActiveQ] = useState("q1");

  const openQ = (id) => {
    setActiveQ(activeQ === id ? "" : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id ? "active-answer" : "";
  };

  const getClassQuestion = (id) => {
    return activeQ === id ? "active-question" : "";
  };

  return (
    <>
      <section className="faq-section">
        <div className="container">
          <div className="faq-content">
            <div className="faq-content__title">
              <h5>FAQ</h5>
              <h2>Frequently Asked Questions</h2>
              <p>
                Frequently Asked Questions About the Car Rental Booking Process
                on Our Website: Answers to Common Concerns and Inquiries.
              </p>
            </div>

            <div className="all-questions"  >
              <div className="faq-box">
                <div
                  style={{
                    backgroundColor: '#9f5e37',
          backgroundImage: 'linear-gradient(285deg, #9f5e37 0%, #3d281a 100%)'
                  }}
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__question  ${getClassQuestion("q1")}`}
                >
                  <p style={{color:'silver'}}>1. Is your car rental rates fixed?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__answer ${getClassAnswer("q1")}`}
                >
                  Certainly! The car rental rates are not fixed and depend on
                  the duration of the hire. Whether you opt for daily, weekly,
                  or monthly rental, the rates vary accordingly. Efficiently
                  evaluating car rental options is crucial, especially when
                  seeking the best deals in the Windhoek, Namibia!. 
                </div>
              </div>
              <div className="faq-box">
                <div
                  style={{
                    backgroundColor: '#9f5e37',
backgroundImage: 'linear-gradient(285deg, #9f5e37 0%, #8a5433 100%)'

                  }}
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__question ${getClassQuestion("q2")}`}
                >
                  <p style={{color:'silver'}}>2. Can I contact you on Whatsapp?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__answer ${getClassAnswer("q2")}`}
                >
                  Yes absolutely, you can contact us on whatsapp aswell as
                  email. Discover the perfect car rental deals in Windhoek, Namibia!.
                </div>
              </div>
              <div className="faq-box">
                <div
                  style={{
                    backgroundColor: '#9f5e37',
                    backgroundImage: 'linear-gradient(285deg, #9f5e37 0%, #3d281a 100%)'
                  }}
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__question ${getClassQuestion("q3")}`}
                >
                 <p style={{color:'silver'}}>3. When was this company established?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__answer ${getClassAnswer("q3")}`}
                >
                 We are servicing customers in Windhoek, Namibia! for over 15 years now.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
