import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import HeroPages from "../components/HeroPages";
import React from "react";
import { db } from "../components/firebase.js";
import {
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  collection,
  addDoc,
} from "firebase/firestore";
import { Helmet } from "react-helmet";


function Contact() {
  {
    
    /*
  const carDatas = [
    
  
  
    {
      name: "Nissan Np 300",
      price: "00",
      img: 'https://wieck-nissanao-production.s3.amazonaws.com/photos/8eef5b584ad157b6cdb6c506b064d4f158745dc9/preview-928x522.jpg',
      air: "4x4 Truck",
      transmission: "Automatic",
      fuel: "2019",
    },
  
    {
      name: "Toyota Corolla Quest",
      price: "00",
      img: 'https://cdn.wheel-size.com/automobile/body/toyota-corolla-quest-2014-2020-1602565357.1947575.jpg',
      air: "Sedan",
      transmission: "Automatic",
      fuel: "2018",
    },

    {
      name: "Toyota Fortuner",
      price: "00",
      img: 'https://cache4.pakwheels.com/system/car_generation_pictures/7335/original/Fortuner_-_PNG.png?1677568997',
      air: "4x4 7 seater",
      transmission: "Automatic",
      fuel: "2024",
    },

{
      name: "Suzuki Fronx Manual",
      price: "00",
      img: 'https://foujiadda.in/admin/uploads//vehicle/image_1693227450.jpeg',
      air: " Mini SUV",
      transmission: "Manual",
      fuel: "2023",
    },


{
      name: "Suzuki Fronx Auto",
      price: "00",
      img: 'https://foujiadda.in/admin/uploads//vehicle/image_1693227450.jpeg',
      air: "Mini SUV",
      transmission: "Automatic",
      fuel: "2023",
    },


{
      name: "Toyota Corolla Cross",
      price: "00",
      img: 'https://newcars.com.pk/toyota/images/cross-white.jpg',
      air: "Mini SUV",
      transmission: "Automatic",
      fuel: "2023",
    },


  {
      name: "Isuzu MUX",
      price: "00",
      img: 'https://resource.digitaldealer.com.au/image/1450762212638d9f2f402b9569053966_0_0-c.jpg',
      air: "SUV",
      transmission: "Automatic",
      fuel: "2023",
    },


{
      name: "Isuzu D-max",
      price: "00",
      img: 'https://www.carscoops.com/wp-content/uploads/2023/10/2024-Isuzu-D-Max-Space-Cab-1-1024x640.jpg',
      air: "4x4 Truck",
      transmission: "Automatic",
      fuel: "2023",
    },

{
      name: "Toyota Hilux",
      price: "00",
      img: 'https://media.toyota.com.ar/5cac1588-48fe-4597-843d-a3dd53d2e41a.png',
      air: "4x4 Truck",
      transmission: "Automatic",
      fuel: "2024",
    },

{
      name: "Suzuki Jimny",
      price: "00",
      img: 'https://www.suzuki.co.nz/images/assets/643799/23',
      air: "Mini SUV",
      transmission: "Automatic",
      fuel: "2021",
    },

{
      name: "Toyota Starlet",
      price: "00",
      img: 'https://www.toyota.gm/media/gamme/modeles/images/824cf4055cbeeb6b28442acecc7e04fa.png',
      air: "Hatchback",
      transmission: "Automatic",
      fuel: "2022",
    },

{
      name: "Toyota Starlet",
      price: "00",
      img: 'https://www.toyota.gm/media/gamme/modeles/images/824cf4055cbeeb6b28442acecc7e04fa.png',
      air: "Hatchback",
      transmission: "Automatic",
      fuel: "2022",
    },


{
      name: "Mercedes Benz E350d",
      price: "00",
      img: 'https://i.pinimg.com/originals/04/f6/0c/04f60cae46243141ec0cf834ac12c8e4.png',
      air: "Sedan",
      transmission: "Automatic",
      fuel: "2020",
    },

{
      name: "VW Transporter",
      price: "00",
      img: 'https://s3-eu-west-1.amazonaws.com/localrent.images/cars/image_titles/000/014/999/original/VW-Caravelle-2019-white_(1).jpg',
      air: "8 Seater Van",
      transmission: "Automatic",
      fuel: "2023",
    },

{
      name: "Toyota Quantum Hiace",
      price: "00",
      img: 'https://cmhtoyota.co.za/wp-content/uploads/site/vehicles/hiace/Hiace-1.jpg',
      air: "Van",
      transmission: "Manual",
      fuel: "2023",
    },


  ];

  const addCarsToFirestore = async () => {
    const carsCollection = collection(db, 'carsdata');
  
    for (const car of carDatas) {
      try {
        await addDoc(carsCollection, car);
        console.log(`Car ${car.name} added to Firestore`);
      } catch (error) {
        console.error(`Error adding car ${car.name} to Firestore:`, error);
      }
    }
  };
  
 
    useEffect(() => {
      addCarsToFirestore();
    }, []); // This will run the function only once on component mount
  
*/
  
  }

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [question, setQuestion] = React.useState("");

  const postmsg = async () => {
    try {
      // Validate input fields
      if (!name || !email || !question) {
        window.alert("Please Fill All Fields!");
        return;
      }
  
      // Get the current date
      const currentDate = new Date();
  
      // Format the date as "YYYY-MM-DD"
      const formattedDate = currentDate.toISOString().split("T")[0];
  
      // Add a new document to the "Messages" collection with the specified fields
      await db.collection("Messages").add({
        cname: name,
        cemail: email,
        cquestion: question,
        date: formattedDate,
      });
  
      window.alert("Your message is sent!");
  
      // Reload the page
      window.location.reload();
      
      console.log("Contact Details Posted!");
    } catch (error) {
      // Log any errors to the console
      console.error(error);
      window.alert("An error occurred. Please try again later.");
    }
  };


  return (
    <>

     <Helmet>
        <title>
          Contact Us -  Ce Car Rental
        </title>

        <meta
          name="description"
          content="Get in touch with CE Car Rental for all your car rental needs and camper rental needs. Explore our diverse fleet of well-maintained vehicles and experience hassle-free transportation in Windhoek, Namibia. Contact us today for inquiries, reservations, or any assistance you may need. Your ultimate car renting experience begins with CE Car Rental!"
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <section className="contact-page">
        <HeroPages name="Contact" />
        <div className="container">


          <div className="row">
            <div className="col-md-6">
              <div className="contact-div">
                <div className="contact-div__text">
                  <a
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "green",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href="https://maps.app.goo.gl/DkKQv8ym1MxbakPb6"
                  >
                    Click For Google Maps Location
                  </a>

                
                <a style={{ fontWeight: "bold", fontSize: "18px" }} href="tel:+264816464376">
                &#x260E; +264 81 646 4376
                </a>
          

                  <a
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                    href="tel: +264 81 319 9459"
                  >
                    &#x260E;+264 81 319 9459
                  </a>


                  <a
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                    href="tel: +264 61 242 554"
                  >
                    &#x260E;+264 61 242 554
                  </a>



                  <a
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                    href="mailto:  cecar.rental@gmail.com"
                  >
                    &#9993; Click To Email Us
                  </a>
                  <a style={{ fontWeight: "bold", fontSize: "18px" }} href="/">
                    &#128205;  Windhoek, Namibia
                  </a>
                  <br></br>
                  <h3>Need additional information?</h3>
                  <p>
                  Explore convenient and affordable vehicle rental with  CE Car Rental. Our diverse fleet of well-maintained vehicles ensures reliable transportation solutions for your exploration needs is available in Windhoek, Namibia and surrounding areas and nearby airports. Enjoy seamless travel experiences with our CE Car Rental– your trusted partner for exploring  Windhoek, Namibia. Book now at <a
                        href="https://maps.app.goo.gl/DkKQv8ym1MxbakPb6"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        office
                      </a>    for an unmatched journey.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-div__form">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    postmsg();
                  }}
                >
                  <label>
                    Full Name <b>*</b>
                  </label>
                  <input
                    type="text"
                    placeholder='E.g: "Joe Shmoe"'
                    onChange={(e) => setName(e.target.value)}
                  />

                  <label>
                    Email <b>*</b>
                  </label>
                  <input
                    type="email"
                    placeholder="youremail@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <label>
                    Tell us about it <b>*</b>
                  </label>
                  <textarea
                    placeholder="Write Here.."
                    onChange={(e) => setQuestion(e.target.value)}
                  ></textarea>

                  <button type="submit" style={{backgroundColor: '#9f5e37',
          backgroundImage: 'linear-gradient(285deg, #9f5e37 0%, #3d281a 100%)'}}   >
                    <i className="fa-solid fa-envelope-open-text"></i>&nbsp;
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3 style={{color:'#7CFC00'}}>☎+264 81 319 9459</h3> 
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Contact;
