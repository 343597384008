import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import BookCarHome from "../components/BookCarHome";
import PickCar from "../components/PickCar";
import Banner from "../components/Banner";
import Bannerz from "../components/clientbanner";
import ChooseUs from "../components/ChooseUs";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import ChooseCamp from "../components/ChooseCamper";

//import Models from "./Models";
import { Helmet } from "react-helmet";
import BookCamperHome from "../components/BookCamperHome";
import BannerMap from "../components/Map";
import PickCamper from "../components/PickCamper";


function Home() {
  return ( 
    <>
      <Helmet>
        <title>
        Home - CE Car Rental        
        </title>

        <meta
          name="description"
          content="Discover the best car rental deals and camper rental deals with CE Car Rental. Providing top-quality car renting services in Windhoek, Namibia. We also provide camper rental services in Windhoek, Namibia. Your ultimate car renting experience awaits with CE Car Rental providing car rental services to Hosea Kutako Airport Windhoek Eros Airport Windhoek,  Walvisbay Airport,  Ondangwa Airport, Katim Mulilo Airport, Rundu Airport,  Katima Mulilo Airport, "
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      

      <Hero />
      <PickCar />
     
      <ChooseUs />
      <Banner />
      <ChooseCamp/>
      <PickCamper/>
      <BookCamperHome/>
     
      <Testimonials />
      <Bannerz/>
      <Faq />
      <BannerMap/>
      <Footer />
     
    </>
  );
}

export default Home;
