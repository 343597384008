function BannerMap() {
  return (
    <>
      <section className="banner-section"style={{  backgroundColor: '#9f5e37',
          backgroundImage: 'linear-gradient(285deg, #9f5e37 0%, #3d281a 100%)',}} >
        <div className="container">
          
        <div style={{ width: '100%', height: '330px',  }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7469.107803626485!2d17.0692637!3d-22.5713599!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1c0b1b3cb084cae9%3A0x893c908b01052e09!2sCE%20Car%20Rentals!5e0!3m2!1sen!2s!4v1634150663559!5m2!1sen!2s
        "
        width="100%"
        height="100%"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>


        </div>
      </section>
    </>
  );
}

export default BannerMap;
