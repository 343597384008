import "./Slider.css"; // Import CSS file for styling
import React, { useState, useEffect } from "react";

function Banner() {
  const sImages =
    "https://gemsofafricasafaris.com/wp-content/uploads/2023/03/Africa-safari-tours-960x636-min.jpg";

  const imagez = [
    {
      url: "https://cdn.rhinoafrica.com/tmp/image-thumbnails/media/_en/destinations/root/africa/southern-africa/namibia/_img/image-thumb__48322__background-cover/elephant-herd-namibia-wildlife-01-main.jpg",
    },
    {
      url: "https://gemsofafricasafaris.com/wp-content/uploads/2023/03/Africa-safari-tours-960x636-min.jpg",
    },

    {
      url: "https://www.travelandleisure.com/thmb/yTXraNrnwAhrDXxesOyo_9hGglE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/safari-truck-giraffes-micato-safaris-SAFARIGUIDETIPS0721-2549bb165aa34dc193cb8b6f3958654b.jpg",
    },
    {
      url: "https://www.zdf-studios.com/sites/default/files/styles/dd_catalog_hero_top/public/media/catalog/posterframe/namibiasnatualwonders-keyvisual-01.jpg?itok=OHhNIIkW",
    },
    {
      url: "https://files.worldwildlife.org/wwfcmsprod/images/Namibia_7.26.2012_species_MID_202398.jpg/story_full_width/4wtzlk8rjh_Namibia_7.26.2012_species_MID_202398.jpg",
    },
  ];

  const images = [
    {
      src: "https://s3-cdn.designerjourneys.com/blog/wp-content/uploads/2021/06/21082727/sergi-ferrete-pWshkmAH4qA-unsplash-1-1170x775.jpg",
    },
    {
      src: "https://gemsofafricasafaris.com/wp-content/uploads/2023/03/Africa-safari-tours-960x636-min.jpg",
    },

    {
      src: "https://greenglobaltravel.com/wp-content/uploads/2023/06/Khaudum-National-Park-Namibia-Elephants.jpg",
    },
    {
      
      src: "https://www.travelandleisure.com/thmb/yTXraNrnwAhrDXxesOyo_9hGglE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/safari-truck-giraffes-micato-safaris-SAFARIGUIDETIPS0721-2549bb165aa34dc193cb8b6f3958654b.jpg",
    },
    {
      src: "https://www.zdf-studios.com/sites/default/files/styles/dd_catalog_hero_top/public/media/catalog/posterframe/namibiasnatualwonders-keyvisual-01.jpg?itok=OHhNIIkW",
    },
    {
      src: "https://images.squarespace-cdn.com/content/v1/5f4781f37678611568d80551/1603468646846-UA8BJTKC9L6MM8MWUQQJ/AdobeStock_nnf.jpg",
    },


  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (url) => {
    setSelectedImage(url);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <section
        className="banner-section"
        style={{
          backgroundColor: "#9f5e37",
          backgroundImage: "linear-gradient(285deg, #9f5e37 0%, #3d281a 100%)", width:'100%'
        }}
      >
        <div className="row" style={{width:'100%'}} >
          <div className="col-12">
            <div className="container">
              <div className="banner-content">
                <div className="banner-content__text">
                  <h2>Best Camper Rental In Windhoek, Namibia!</h2>
                  <p>
                    Book your camper now at{" "}
                    <span style={{ color: "gold" }}>+264 81 319 9459</span>
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {images.map((image, index) => (
          <div key={index} style={{ width: '33%', padding: '5px' }}>
            <img src={image.src} alt={'Image ' + index} style={{ width: '100%', aspectRatio: '16/9', cursor: 'pointer' }} onClick={() => openImage(image.src)} />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={closeImage}>
          <div style={{position:'absolute', color:'white' ,backgroundColor:'red', fontSize: '1.4em', marginBottom:'35%', marginRight:'75%', padding:'8px', borderRadius:'40px', fontWeight:'bold' }}>Close</div>
          <img src={selectedImage} alt="Selected Image" style={{ maxWidth: '100%', maxHeight: '90%', objectFit: 'contain' }} />
        </div>
      )}
    </div>


        </div>
      </section>
    </>
  );
}

export default Banner;
