function Footer() {



  function handleSubscribe() {
    alert('Thanks for subscribing!!!');
  }



  return (
    <>
      <footer style={{marginTop:'-100px'}}>
        <div className="container"> 
          <div className="footer-content">


          <ul className="footer-content__2">
              <li>Contact Us</li>
              
              <li style={{ fontSize: "14px", fontWeight:'bold' }}  >
                <a href="tel:+264816464376">
                &#x260E; +264 81 646 4376
                </a>
              </li>


              <li style={{ fontSize: "14px", fontWeight:'bold' }}  >
                <a href="tel:+264 81 319 9459">
                &#x260E; +264 81 319 9459
                </a>
              </li>

              <li style={{ fontSize: "14px", fontWeight:'bold' }}  >
                <a href="tel:+264 61 242 554">
                &#x260E;  +264 61 242 554
                </a>
              </li>
             


              <li  style={{ fontSize: "14px", fontWeight:'bold' }} >
                <a
                  href="mailto: 
                  cecar.rental@gmail.com"
                >
                
                  &#9993;  cecar.rental@gmail.com
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px", fontWeight:'bold' }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://maps.app.goo.gl/DkKQv8ym1MxbakPb6"
                 // href="https://jephunneh.com/"
                >
                 &#128205; 
                 13 Sandpiper Street, Windhoek, Namibia
                </a>
              </li>
              
            </ul>



            <ul className="footer-content__1">
              <li>
                <span style={{color:'brown'}}>CE Car</span><span> Rental</span>
              </li>
             
             

              <li  style={{ fontSize: "13px" }}>
                We offers a big range of vehicles for all your car rental needs. We
                have the perfect car to meet your needs. 
              </li>


              <li  style={{ fontSize: "13px" }}>
              <span>Namibian Tourism Board Number:</span>
              <span> (VEH01046)</span> 
              </li>
            

              <li>
                <a
                  style={{ fontSize: "14px"}}
                  target="_blank"
                  rel="noreferrer"
                  href="https://dev2code.com"
                >
                  <span>Website By:</span> 
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px", fontWeight:'bold'}}
                  target="_blank"
                  rel="noreferrer"
                  href="https://web4business.tech" 
                >
                  <span style={{color:'brown' }}>Qasim</span> 
                </a>
              </li>

  
            </ul>

            <ul className="footer-content__2">
              <li>Company</li>
              <li>
                <a   href="https://maps.app.goo.gl/DkKQv8ym1MxbakPb6">Our Location</a>
              </li>
              <li>
                <a href="/">Careers</a>
              </li>
              <li>
               <a href="/">Mobile</a>
              </li>
              

              <li>
              <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://admincecarrental.netlify.app"
                 // 
                >
              
                   Admin  &#128187;
                </a>
              </li>


            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>Mon - Sat: 8:00AM - 5:00PM</li>
              <li>Sun: Open on appointment</li>
            </ul>

          
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
