

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage'
import 'firebase/compat/firestore';

const firebaseConfig = {

    apiKey: "AIzaSyBzhD761pI-jHJ3uqhPnT_i-zS0aAorQVQ",

    authDomain: "ce-car-rental.firebaseapp.com",
  
    projectId: "ce-car-rental",
  
    storageBucket: "ce-car-rental.appspot.com",
  
    messagingSenderId: "751565896148",
  
    appId: "1:751565896148:web:f9ebd6bda2656b4fe47e41"
  


    //apiKey: "AIzaSyBPlZVMe21VIkzlp8C5KeMMdCHBZPTf7-U",

    //authDomain: "hernandez-car-rental.firebaseapp.com",
  
    //projectId: "hernandez-car-rental",
  
   // storageBucket: "hernandez-car-rental.appspot.com",
  
   // messagingSenderId: "504014633043",
  
   // appId: "1:504014633043:web:d35906ceb59efebe83aea6"
  
  
  };
  
  
  
let app;

if (firebase.apps.length === 0) {
    app = firebase.initializeApp(firebaseConfig)
} else {
    app = firebase.app();
}

const db = app.firestore();


export {db} 