import "./Slider.css"; // Import CSS file for styling
import React, { useState, useEffect } from "react";
import image1 from "../images/chooseUs/1.jpeg";
import image2 from "../images/chooseUs/2.jpeg";
import image3 from "../images/chooseUs/3.jpeg";
import image4 from "../images/chooseUs/4.jpeg";
import image5 from "../images/chooseUs/5.jpeg";
import image6 from "../images/chooseUs/6.jpeg";
import image7 from "../images/chooseUs/7.jpeg";
import image8 from "../images/chooseUs/8.jpeg";
import image9 from "../images/chooseUs/9.jpeg";

function Bannerz() {
 

  const images = [
    {
      src: image1,
    },
    {
      src: image2,
    },

    {
      src: image3,
    },
    {
      src: image4,
    },
    {
      src: image5,
    },
    {
      src: image6,
    },

    {
      src: image7,
    },
    {
      src: image8,
    },
    {
      src: image9,
    },



  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (url) => {
    setSelectedImage(url);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <section
        className="banner-section"
        style={{
          backgroundColor: "#9f5e37",
          backgroundImage: "linear-gradient(285deg, #9f5e37 0%, #3d281a 100%)", width:'100%'
        }}
      >
        <div className="row" style={{width:'100%'}}  >
        
          <div className="col-12">
            <div className="container">
              <div className="banner-content" >
                <div className="banner-content__text" style={{width:'100%'}}>
                  <h2 style={{textAlign:'center'}}>Lovely Photos From Our Client Diaries</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" >
          

          <div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {images.map((image, index) => (
          <div key={index} style={{ width: '33%', padding: '5px' }}>
            <img src={image.src} alt={'Image ' + index} style={{ width: '100%', aspectRatio: '16/9', cursor: 'pointer' }} onClick={() => openImage(image.src)} />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={closeImage}>
          <div style={{position:'absolute', color:'white' ,backgroundColor:'red', fontSize: '1.4em', marginBottom:'35%', marginRight:'75%', padding:'8px', borderRadius:'40px', fontWeight:'bold' }}>Close</div>
          <img src={selectedImage} alt="Selected Image" style={{ maxWidth: '100%', maxHeight: '90%', objectFit: 'contain' }} />
        </div>
      )}
    </div>



          
          </div>
        </div>
      </section>
    </>
  );
}


export default Bannerz;
